<template>
  <BaseDialog
    v-if="dialog"
    :dialogVisible.sync="dialog"
    :title="isAdd ? '新增角色' : '编辑角色'"
    width="30%"
    @closed="onClose"
    @openHandle="onOpen"
  >
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px">
      <el-form-item label="角色名称" prop="roleName">
        <el-input v-model="form.roleName" style="width: 370px" placeholder="请输入名称" />
      </el-form-item>
      <el-form-item label="角色编号" prop="roleCode">
        <el-input v-model="form.roleCode" style="width: 370px" placeholder="请输入名称" />
      </el-form-item>
      <el-form-item
        label="默认角色"
        prop="isDefault"
        v-if="data.clientType === CLIENT_TYPE_EXTERNAL_BUSINESS || clientType === CLIENT_TYPE_EXTERNAL_BUSINESS"
      >
        <el-radio-group v-model="form.isDefault">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="角色描述">
        <el-input v-model="form.description" placeholder="请输入描述" style="width: 370px" rows="5" type="textarea" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </BaseDialog>
</template>

<script>
import { CLIENT_TYPE_EXTERNAL_BUSINESS } from '@/utils/constant'
import { deepClone, getDiffData } from '@/components/avue/utils/util'
import { validatenull } from '@/components/avue/utils/validate'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    data: {
      type: Object,
      default: () => ({})
    },
    clientType: {
      type: Number
    }
  },
  data() {
    return {
      CLIENT_TYPE_EXTERNAL_BUSINESS,
      loading: false,
      dialog: false,
      form: { roleName: '', roleCode: '', description: '' },
      rules: {
        roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        roleCode: [{ required: true, message: '请输入角色编号', trigger: 'blur' }]
      }
    }
  },
  watch: {
    data: {
      handler(n) {
        if (!this.isAdd) {
          this.form = deepClone(n)
          if (this.data.clientType === CLIENT_TYPE_EXTERNAL_BUSINESS && !this.form.isDefault) {
            // this.form.isDefault = 0
            this.$set(this.form, 'isDefault', 0)
          }
        }
      },
      immediate: true
    },
    clientType(n) {
      if (n === CLIENT_TYPE_EXTERNAL_BUSINESS) {
        this.$set(this.form, 'isDefault', 0)
        // this.form.isDefault = 0
      } else {
        delete this.form.isDefault
      }
    }
  },
  methods: {
    cancel() {
      this.dialog = false
      this.resetForm()
    },
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else this.doEdit()
        } else {
          return false
        }
      })
    },
    onClose() {
      Object.assign(this, this.$options.data())
    },
    onOpen() {
      this.$refs?.form?.clearValidate()
    },
    async doAdd() {
      let [, res] = await awaitWrap(this.sup_this.save(this.form))
      if ($SUC(res)) {
        this.resetForm()
        this.$message({
          showClose: true,
          type: 'success',
          message: '添加成功!',
          duration: 2500
        })
        this.sup_this.init()
      }
      this.loading = false
    },
    async doEdit() {
      let diffData = getDiffData(this.form, this.data)
      let isSuccess = true
      if (!validatenull(diffData)) {
        let [, res] = await awaitWrap(
          this.sup_this.save({
            ...diffData,
            id: this.form.id
          })
        )
        isSuccess = $SUC(res)
      }
      if (isSuccess) {
        this.resetForm()
        this.$message({
          showClose: true,
          type: 'success',
          message: '修改成功!',
          duration: 2500
        })
        this.sup_this.init()
      }
      this.loading = false
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = { roleName: '', roleCode: '', description: '', isDefault: 0 }
    }
  }
}
</script>

<style scoped></style>
